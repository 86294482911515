import { render, staticRenderFns } from "./FormularioChapuzon.vue?vue&type=template&id=49d2b716&scoped=true&"
import script from "./FormularioChapuzon.vue?vue&type=script&lang=ts&"
export * from "./FormularioChapuzon.vue?vue&type=script&lang=ts&"
import style0 from "./FormularioChapuzon.vue?vue&type=style&index=0&id=49d2b716&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d2b716",
  null
  
)

export default component.exports