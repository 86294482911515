



























































































































































































































































import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

interface SelectOption {
  value: string | any;
  label: string;
}

@Component({
  components: {}
})
export default class FormularioOIRS extends Mixins(FormValidator) {
  private win = window;
  private base = window.location;
  private rutContacto = "";
  private data: any = {
    rut: "",
    nombre: "",
    email: "",
    telefono: "",
    direccion: "",
    mensaje: "",
    motivo: "",
    sexo: "",
    edad: "",
    escolaridad: ""
  };

  private dataMotivo: SelectOption | null = null;
  private motivoOptions: Array<SelectOption> = [
    { value: 1, label: "Consulta" },
    { value: 2, label: "Sugerencia" },
    { value: 3, label: "Reclamo" },
    { value: 4, label: "Felicitaciones" }
  ];
  private dataGenero: SelectOption | null = null;
  private generoOptions: Array<SelectOption> = [
    { value: 1, label: "Masculino" },
    { value: 5, label: "Femenino" },
    { value: 2, label: "Otro" },
    { value: 3, label: "Prefiero no decirlo" },
    { value: 4, label: "No binario" }
  ];
  private dataEdad: SelectOption | null = null;
  private edadOptions: Array<SelectOption> = [
    { value: "0-18", label: "Menor de 18 años" },
    { value: "19-24", label: "De 19 a 24 años" },
    { value: "25-35", label: "De 25 a 35 años" },
    { value: "36-45", label: "De 36 a 45 años" },
    { value: "46-55", label: "De 46 a 55 años" },
    { value: "56-64", label: "De 56 a 64 años" },
    { value: "65-mas", label: "Mayor de 65 años" }
  ];
  private dataEscolaridad: SelectOption | null = null;
  private escolaridadOptions: Array<SelectOption> = [
    {
      value: "Enseñanza Básica Incompleta",
      label: "Enseñanza Básica Incompleta"
    },
    { value: "Enseñanza Básica Completa", label: "Enseñanza Básica Completa" },
    {
      value: "Enseñanza Media Incompleta",
      label: "Enseñanza Media Incompleta"
    },
    { value: "Enseñanza Media Completa", label: "Enseñanza Media Completa" },
    {
      value: "Enseñanza Técnico Profesional",
      label: "Enseñanza Técnico Profesional"
    },
    {
      value: "Estudios Universitarios Incompletos",
      label: "Estudios Universitarios Incompletos"
    },
    {
      value: "Estudios Universitarios Completos",
      label: "Estudios Universitarios Completos"
    }
  ];

  @Watch("rutContacto")
  private formatRutVecino(newValue: string) {
    newValue = newValue.replace(/\./g, "").toUpperCase();
    this.data.rut = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.rutContacto = `${rut}-${dv}`;
    } else {
      this.rutContacto = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  @Watch("dataMotivo")
  private motivoWatcher(newValue: any) {
    this.data.motivo = newValue.value;
  }
  @Watch("dataGenero")
  private sexoWatcher(newValue: any) {
    this.data.sexo = newValue.value;
  }
  @Watch("dataEdad")
  private edadWatcher(newValue: any) {
    this.data.edad = newValue.value;
  }
  @Watch("dataEscolaridad")
  private escolaridadWatcher(newValue: any) {
    this.data.escolaridad = newValue.value;
  }

  private mounted() {
    document.title = "Formulario OIRS - Ilustre Municipalidad de Punta Arenas";
  }

  private enviar() {
    if (this.data.rut.trim() == "" || !this.validateRut(this.data.rut)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Rut válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.data.nombre.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un nombre.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (!this.data.motivo || this.data.motivo == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, seleccione un motivo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (!this.data.sexo || this.data.sexo == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, seleccione un género.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (!this.data.edad || this.data.edad == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, seleccione un rango de edad.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (!this.data.escolaridad || this.data.escolaridad == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, seleccione un nivel de escolaridad.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.data.email == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Correo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (!this.validEmail(this.data.email)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un correo válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.data.telefono == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Teléfono.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.data.telefono.length < 9) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un teléfono válido (9 dígitos).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.data.direccion == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su dirección.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.data.mensaje == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese el mensaje que desea enviar.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .post("formulario/oirs", this.data)
      .then((res: any) => {
        this.$q.loading.hide();
        Notify.create({
          color: "positive",
          message: "Se ha enviado correctamente su mensaje.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        this.routerGo("Home");
        return;
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
        Notify.create({
          color: "negative",
          message: "Ha ocurrido un error, por favor intente nuevamente.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private validEmail(email: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
  public validateRut(newValue: string) {
    let texto = newValue;
    let tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    const largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    let invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    let dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    let cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    const dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    const largo = crut.length;
    if (largo < 2) {
      return false;
    }
    let rut = "";
    if (largo > 2) rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    const dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    let dvr = "0";
    let suma = 0;
    let mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    const res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      const dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
}
